<template>
  <div id="advertise-default">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>广告管理</el-breadcrumb-item>
      <el-breadcrumb-item>默认广告</el-breadcrumb-item>
    </el-breadcrumb>
    <adList :advtisementType="advtisementType" />
  </div>
</template>
<script>
import adList from "../components/adList.vue";
export default {
  components: {
    adList,
  },
  data() {
    return {
      advtisementType: "平台",
    };
  },
};
</script>
<style lang="scss">
#advertise-default {
}
</style>
