<template>
  <div id="advertise-adList">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li v-if="advtisementType === '平台'">
            <span class="label">广告位置:</span>
            <el-select
              v-model="filtrate.adPosition"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_adPosition"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">广告状态:</span>
            <el-select
              v-model="filtrate.adStatus"
              placeholder="请选择"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_adStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">发布状态:</span>
            <el-select
              v-model="filtrate.publishStatus"
              placeholder="请选择"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_publishStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="create_btn"
              type="primary"
              @click="ad_create"
              v-if="buttonList.includes('advertise-list-add')"
              ><i class="iconfont icon-jiahao"></i> 新建广告</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="advtisementIndex" label="序号" align="center">
        </el-table-column>
        <el-table-column label="图片" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.picFileUrl"
              :preview-src-list="[scope.row.picFileUrl]"
              fit="cover"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="advtisementName" label="广告名称" align="center">
        </el-table-column>
        <el-table-column
          prop="advtisementPosition"
          label="广告位置"
          align="center"
        >
        </el-table-column>
        <el-table-column label="广告状态" align="center">
          <template slot-scope="scope">
            <div class="advtisementStatus" :class="scope.row.statusClass">
              <i class="advertise-status"></i>
              {{ scope.row.advtisementStatus }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="发布状态" align="center">
          <template slot-scope="scope">
            <div
              class="advtisementStatus"
              :class="scope.row.publishStatusClass"
            >
              <i class="advertise-status"></i>
              {{ scope.row.publishStatus }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="username" label="创建人" align="center">
        </el-table-column>
        <el-table-column prop="createAt" label="创建时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="
                scope.row.advtisementStatus === '审核中' &&
                buttonList.includes('advertise-list-verify')
              "
              @click="ad_verify(scope.row.advtisementId)"
              >审核</el-button
            >
            <el-button
              type="text"
              v-if="
                scope.row.advtisementStatus === '审核通过' &&
                scope.row.publishStatus === '已下架' &&
                buttonList.includes('advertise-list-publishStatus1')
              "
              @click="ad_publishStatus(scope.row.advtisementId, '已发布')"
              >上架</el-button
            >
            <el-button
              type="text"
              v-if="
                scope.row.advtisementStatus === '审核通过' &&
                scope.row.publishStatus === '已发布' &&
                buttonList.includes('advertise-list-publishStatus0')
              "
              @click="ad_publishStatus(scope.row.advtisementId, '已下架')"
              >下架</el-button
            >
            <el-button
              type="text"
              @click="ad_edit(scope.row)"
              v-if="buttonList.includes('advertise-list-edit')"
              >编辑</el-button
            >
            <el-button
              v-if="buttonList.includes('advertise-list-delete')"
              type="text"
              @click="ad_delete(scope.row.advtisementId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <actionDialog
      ref="actionDialog"
      :advtisementType="advtisementType"
      @confirm="getList"
    />
    <verifyDialog ref="verifyDialog" @confirm="getList" />
  </div>
</template>
<script>
export default {
  components: {
    actionDialog: () => import("./actionDialog.vue"),
    verifyDialog: () => import("./verifyDialog.vue"),
  },
  props: {
    advtisementType: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        adPosition: "首页活动",
        adStatus: "",
        publishStatus: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    if (this.advtisementType === "停车场") {
      this.filtrate.adPosition = "支付广告";
    }
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate.keyword = "";
      this.filtrate.adStatus = "";
      this.filtrate.publishStatus = "";
      if (this.advtisementType === "区域") {
        this.filtrate.adPosition = "首页活动";
      } else {
        this.filtrate.adPosition = "支付广告";
      }
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/ad/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          adType: this.advtisementType,
          ...this.filtrate,
          adStatus: this.filtrate.adStatus ? this.filtrate.adStatus : null,
          publishStatus: this.filtrate.publishStatus
            ? this.filtrate.publishStatus
            : null,
          parkingLotId:
            this.advtisementType === "停车场"
              ? this.$route.params.parkingLotId
              : null,
          areaId:
            this.advtisementType === "区域" ? this.$route.params.areaId : null,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.createAt = this.$moment(item.createAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            switch (item.advtisementStatus) {
              case "审核中":
                item.statusClass = "advertise-status-0";
                break;
              case "审核未通过":
                item.statusClass = "advertise-status-1";
                break;
              case "审核通过":
                item.statusClass = "advertise-status-2";
                break;
            }
            switch (item.publishStatus) {
              case "已发布":
                item.publishStatusClass = "advertise-status-2";
                break;
              case "已下架":
                item.publishStatusClass = "advertise-status-3";
                break;
            }
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
    ad_create() {
      this.$refs.actionDialog.show = true;
    },
    ad_verify(advtisementId) {
      this.$refs.verifyDialog.advtisementId = advtisementId;
      this.$refs.verifyDialog.show = true;
    },
    ad_publishStatus(advtisementId, publishStatus) {
      this.$confirm("此操作将改变广告的发布状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$http.post("/ad/edit", {
          advtisementId,
          publishStatus,
        });
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    ad_edit(row) {
      this.$refs.actionDialog.advtisementId = row.advtisementId;
      for (let key in this.$refs.actionDialog.form) {
        this.$refs.actionDialog.form[key] = row[key];
      }
      this.$refs.actionDialog.previewUrl = row.picFileUrl;
      this.$refs.actionDialog.show = true;
    },
    ad_delete(advtisementId) {
      this.$confirm("此操作将永久删除该广告, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$http.delete(`/ad/delete/${advtisementId}`);
        if (res.code === 0) {
          this.$message.success("删除成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss">
#advertise-adList {
  .advtisementStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    .advertise-status {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 5px;
      background-color: white;
      margin-right: 4px;
    }
  }
  .advertise-status-0 {
    color: #5f8cee;
    .advertise-status {
      background-color: #5f8cee;
    }
  }
  .advertise-status-1 {
    color: red;
    .advertise-status {
      background-color: red;
    }
  }
  .advertise-status-2 {
    color: rgb(115, 240, 115);
    .advertise-status {
      background-color: rgb(115, 240, 115);
    }
  }
  .advertise-status-3 {
    color: rgb(182, 173, 176);
    .advertise-status {
      background-color: rgb(182, 173, 176);
    }
  }
}
</style>
